import { ref, reactive } from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useSubject() {
    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]);
    const subject = ref({});
    const subjects = ref([]);
    const childrens = ref([]);
    const lists = reactive({
        year: [],
        levels: [],
        grade_items: [],
        teachers: []
    });

    const getSubjects = async (year_id = null, search_fields = {}) => {
        try {
            let data = search_fields;
            data.year_id = year_id;
            let response = await axios.post('/api/subjects/get', data);
            subjects.value = response.data.data.subjects;
            lists.year = response.data.data.year;
            lists.terms = response.data.data.terms;
            lists.levels = response.data.data.levels;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getSubject = async (id) => {
        try {
            let response = await axios.get('/api/subjects/' + id);
            subject.value = response.data.data.subject;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const createSubject = async (year_id) => {
        try {
            let response = await axios.get('/api/subjects/create/' + year_id);
            lists.year = response.data.data.year;
            lists.levels = response.data.data.levels;
            lists.grade_items = response.data.data.grade_items;
            lists.teachers = response.data.data.teachers;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const storeSubject = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/subjects/', data._rawValue);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    const deleteSubject = async (id) => {
        try {
            await axios.delete('/api/subjects/' + id);
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }

    const editSubject = async (id) => {
        try {
            let response = await axios.get('/api/subjects/' + id + '/edit');
            subject.value = response.data.data.subject;
            lists.year = response.data.data.year;
            lists.levels = response.data.data.levels;
            lists.grade_items = response.data.data.grade_items;
            lists.teachers = response.data.data.teachers;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }


    const updateSubject = async (data, id) => {
        errors.value = ''; //
        try {
            data.value._method = 'PATCH';
            let response = await axios.post('/api/subjects/' + id, data._rawValue);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const sendStudentsgradesReport = async (students, subject_id) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/subjects/send_grades_report', { 'student_ids': students._rawValue, 'subject_id': subject_id });
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    // const exportStudentsSubjectGrades = async (students, subject) => {
    //     errors.value = ''; //
    //     try {
    //         let response = await axios.post('/api/subjects/export_students_subject_grades', { 'student_ids': students._rawValue, 'subject_id': subject.id });
    //         console.log('response.data.data', response.data.data);
    //         return response.data.data;
    //     } catch (e) {
    //         errors.value = await store.dispatch("handleException", e);
    //         return 'fail';
    //     }
    // }

    const exportStudentsSubjectGrades = async (students, subject) => {
       
        axios({
            url: '/api/subjects/export_students_subject_grades',
            method: 'POST',
            responseType: 'blob',
            data:  { 'student_ids': students._rawValue, 'subject_id': subject.id }
        }).then((response) => {

            //let fileExtension = response.data.type.split('/').pop();

            var d = new Date();
            var date = d.toUTCString();

            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', subject.name +' ' + date + '.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();
        });
    }



    const importantStudentsSubjectGrades = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/subjects/import_students_subject_grades', data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const getParentChildrens = async () => {
        try {
            let response = await axios.post('/api/get_parent_childrens');
            childrens.value = response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
        }
    }


    return {
        lists,
        subject,
        subjects,
        msgs,
        errors,
        childrens,
        getSubject,
        getSubjects,
        createSubject,
        storeSubject,
        deleteSubject,
        editSubject,
        updateSubject,
        sendStudentsgradesReport,
        exportStudentsSubjectGrades,
        importantStudentsSubjectGrades,
        getParentChildrens,
    }
}